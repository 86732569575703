import React, {useEffect, useState} from 'react';
import {graphql, Link} from 'gatsby';
import PageHolder from '../components/organisms/PageHolder';
import SEO from '../components/molecules/SEO';
import Container from '../components/atoms/Container'
import styled from 'styled-components';
import {GatsbyImage} from 'gatsby-plugin-image';
import RichTextConverter from '../components/atoms/RichTextConverter';
import LeftArrow from '../assets/left-arrow.inline.svg'
import Motif from '../assets/motif.inline.svg'
import {useWindowSize} from "react-use";
import WeirdTextGrid from "../components/organisms/WeirdTextGrid";
import WeirdDetailsHolder from "../components/organisms/WeirdDetailsHolder";
import WeirdListHolder from "../components/organisms/WeirdListHolder";

const FeaturedImageHolder = styled.div`
  margin: 2rem 0
`;

const Banner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 1rem;
  flex-direction: column;
  gap: 5rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }

  svg {
    height: 1rem;
    width: auto;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      height: unset;
      width: unset;
    }
  }

  button {
    ${props => props.theme.fluidType(-2.8)};
    padding: 0.2rem 0.4rem;
    width: fit-content;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-0.5)};
    }
  }
`

const Heading = styled.div`
  h1 {
    ${props => props.theme.fluidType(4)};
    margin: 1rem 0;
    line-height: 1;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      margin: 4rem 0 0 0;
      ${props => props.theme.fluidType(3)};
    }
  }
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  line-height: 2;
  font-family: 'Eliza Mono', Helvetica, Arial, sans-serif;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    gap: 4rem;
  }

  div {
    p {
      margin: 0;
    }

    p:first-child {
      margin: 0 0 0.5rem 0;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
    margin-top: 0.8rem;

    li {
      margin: 0;
    }
  }
`;

const ContentHolder = styled.div`
  display: flex;
  column-gap: 1rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    align-content: start;
    grid-template-columns: 1fr 4.5fr;
  }
`;

const ImageHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-auto-flow: column;
  grid-auto-columns: minmax(100%, 1fr);
  overflow-x: auto;
  gap: 2rem;
  margin: 2rem 0;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  . gatsby-image-wrapper {
    height: auto;
    width: 100%;
    flex: none;
  }
`;

const SingleImage = styled.div`
  width: 100%;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 0 3rem;
    margin-bottom: 2rem;
  }

  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
`;


function Projects({data}) {
    const post = data.contentfulProject;
    const [mobile, setMobile] = useState(null)
    const size = useWindowSize()

    useEffect(() => {
        if (size.width < 768) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [size.width, setMobile]);

    return (
        <PageHolder bg="cream" transitionFrom="right" paddingTop={true}>
            <SEO title={post.title}/>
            <Container>
                <Banner>
                    <Link to="/work"><LeftArrow/></Link>
                    {post.type === "Case Study" && <button className="button fancy orange">{post.type}</button>}
                    {post.type === "Showcase" && <button className="button fancy yellow">{post.type}</button>}
                </Banner>
                <Heading>
                    <h1 className="h2 eliza-mono">{post.title}</h1>
                </Heading>
                <WeirdDetailsHolder>
                    <Details>
                        {post.location &&
                            <div><p>Location:</p><p>{post.location}</p></div>
                        }
                        {post.year && <div><p>Year: </p><p>{post.year}</p></div>}
                        {post.services && <div>
                            <p>Core Service:</p>
                            <ul>{post.services.map(service =>
                                <li key={service}>{service}</li>)}</ul>
                        </div>}
                        {post.client &&
                            <div><p>Client: </p><p> {post.client}</p></div>
                        }
                    </Details>
                </WeirdDetailsHolder>
                <ContentHolder>
                    {!mobile && <WeirdListHolder>
                        {post.designers && <p>Designers:</p>}
                        {post.designers && <ul>{post.designers.map(designer =>
                            <li key={designer}><p>{designer}</p></li>)}</ul>}
                        {post.suppliers && <p>Suppliers:</p>}
                        {post.suppliers && <ul>{post.suppliers.map(supplier =>
                            <li key={supplier}><p>{supplier}</p></li>)}</ul>}
                    </WeirdListHolder>}
                    <WeirdTextGrid>
                        <RichTextConverter content={post.headline}/>
                    </WeirdTextGrid>
                </ContentHolder>
            </Container>
            {post.type === "Case Study" && <FeaturedImageHolder>
                <GatsbyImage
                    style={{aspectRatio: 16 / 9}}
                    alt={post.title}
                    layout="fullWidth"
                    image={post.featuredImage.gatsbyImageData}/>
            </FeaturedImageHolder>}
            <Container>
                <div style={{marginBottom: '8rem'}}>
                    {post.text && <ContentHolder>
                        {!mobile && <div></div>}
                        {mobile && <WeirdListHolder>
                            {post.designers && <p>Designers:</p>}
                            {post.designers && <ul>{post.designers.map(designer =>
                                <li key={designer}><p>{designer}</p></li>)}</ul>}
                            {post.suppliers && <p>Suppliers:</p>}
                            {post.suppliers && <ul>{post.suppliers.map(supplier =>
                                <li key={supplier}><p>{supplier}</p></li>)}</ul>}
                        </WeirdListHolder>}
                        <WeirdTextGrid>
                            <div className="image-grid">
                                <div>
                                    <RichTextConverter content={post.text}/>
                                    {(!post.text2 && !post.text3) && <Motif/>}
                                </div>
                            </div>
                        </WeirdTextGrid>
                    </ContentHolder>}
                    {post.type === "Case Study" && <SingleImage>
                        {post.images[0] &&
                            <GatsbyImage
                                alt={post.title}
                                layout="fullWidth"
                                image={post.images[0].gatsbyImageData}/>}
                    </SingleImage>}
                    {(post.text2 || post.quote) && <ContentHolder>
                        <div>
                        </div>
                        <WeirdTextGrid>
                            <div className="image-grid">
                                <h3 className="h3 quote">{post.quote}</h3>
                                <div className="margined"><RichTextConverter content={post.text2}/>
                                    {!post.text3 && <Motif/>}</div>
                            </div>
                        </WeirdTextGrid>
                    </ContentHolder>}
                    {post.type === "Case Study" && <ImageHolder>
                        {post.images[1] && <GatsbyImage
                            alt={post.title}
                            layout="fullWidth"
                            image={post.images[1].gatsbyImageData}/>}
                        {post.images[2] && <GatsbyImage
                            alt={post.title}
                            layout="fullWidth"
                            image={post.images[2].gatsbyImageData}/>}
                    </ImageHolder>}
                    {post.text3 && <ContentHolder>
                        <div>
                        </div>
                        <WeirdTextGrid>
                            <div className="image-grid">
                                <div className="margined"><RichTextConverter content={post.text3}/>
                                    <Motif/></div>
                            </div>
                        </WeirdTextGrid>
                    </ContentHolder>}
                </div>
            </Container>
            {post.type === "Case Study" && <FeaturedImageHolder>
                {post.featuredImage2 && <GatsbyImage
                    alt={post.title}
                    layout="fullWidth"
                    image={post.featuredImage2.gatsbyImageData}/>}
            </FeaturedImageHolder>}
            {post.type === "Showcase" && <SingleImage>
                {post.images && post.images.map(image => <GatsbyImage
                    key={image.id}
                    alt={post.title}
                    layout="fullWidth"
                    image={image.gatsbyImageData}/>)}
            </SingleImage>}
        </PageHolder>
    )
}

export default Projects;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulProject(id: {eq: $id}) {
            id
            slug
            title
            type
            year
            quote
            location
            designers
            services
            suppliers
            status
            client
            featuredImage {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            featuredImage2 {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            headline {
                raw
            }
            text {
                raw
            }
            text2 {
                raw
            }
            text3 {
                raw
            }
            images {
                id
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
        }
    }
`;
